import '../styles/index.scss';
function loadHTML (htmlRelativeUrl, baseUrl) {
  // fetch resolves relative url using the document base URL
  const htmlUrl = new URL(htmlRelativeUrl, baseUrl).href;
  return fetch(htmlUrl).then(response => response.text());
}

window.addEventListener("load", function() {
  let isClick = false;
  function openStory () {
    if (isClick) return;
    isClick = true;
    fetch('./stories.html').then(response => {
      let promise = response.text();
      promise.then((res) => {
        var newDiv = document.createElement("div");
        newDiv.setAttribute("id", "rootA");
        newDiv.innerHTML = res;
        document.body.appendChild(newDiv);
        setTimeout(() => {
          function closeStory () {
            let root = document.querySelector("#rootA");
            isClick = false;
            root.remove();
            console.log(document.body);
            document.body.style.overflowY = "auto";
          }
          let canvas = document.querySelector(".close-story");
          document.addEventListener('swiped', function(e) {
            closeStory ();
        });
          canvas.addEventListener("click", closeStory);
        });
      });
    });
  }
  let el = document.querySelectorAll(".block-only-img");
  let arrayEl = [...el];
  arrayEl.forEach((item) => {
    item.addEventListener("click", openStory, false);
  });
});